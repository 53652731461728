import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Button,
  Tooltip,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';

import {
  cancelSubscriptionById,
  pauseSubscriptionById,
  resumeSubscriptionById,
  createCustomerPortalSession,
} from '~/services/stripeApi';
import { capitalizeFirstLetters } from '~/views/private/billings/utils';
import { CheckCircle } from '~/views/private/overview/Components/icons/CheckCircle';
import { Timer } from '~/views/private/overview/Components/icons/Timer';
import { Warning } from '~/views/private/overview/Components/icons/Warning';
import { CalendarX } from '~/views/private/overview/Components/icons/CalendarX';
import { PauseCircle } from '~/views/private/overview/Components/icons/PauseCircle';
import { CancelCircle } from '~/views/private/overview/Components/icons/CancelCircle';
import { Close } from '~/views/private/billings/components/icons/Close';
import { LeadIcon2 } from '~/views/private/overview/Components/icons/LeadIcon2';

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: 'flex',
    padding: 'var(--utilities-size-1-500)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-500)',
    alignSelf: 'stretch',
    borderTop: '1px solid var(--border-default)',
    background: 'var(--surface-pale)',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-0-250)',
    alignSelf: 'stretch',
  },
  text: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-125)',
  },
  link: {
    color: 'var(--content-accent)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    '&:hover': {
      color: 'var(--content-hover-accent-hover)',
    },
  },
  chip: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-000)',
    width: '148px',
    color: 'var(--content-inverted)',
    border: '1px solid var(--content-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  icon: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    marginRight: 'var(--utilities-size-0-500)',
  },
  dialogTitle: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  dialogTitleContainer: {
    // cursor: 'move',
    display: 'flex',
    width: '100%',
    padding: 'var(--utilities-size-1-250) var(--utilities-size-1-500)',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
      width: '580px',
      padding: 'var(--utilities-size-none, 0px)',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 'var(--utilities-size-none)',
      borderRadius: 'var(--utilities-border-radius-xl)',
      background: 'var(--surface-default)',
      boxShadow: 'var(--shadows-modal)',
    },
  },
  closeIcon: {
    width: 'var(--utilities-size-1-500)',
    height: 'var(--utilities-size-1-500)',
    cursor: 'pointer',
  },
  dialogMainContainer: {
    display: 'flex',
    padding:
      'var(--utilities-size-none) var(--utilities-size-1-500) var(--utilities-size-1-500) var(--utilities-size-1-500)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-500)',
    alignSelf: 'stretch',
    background: 'var(--surface-default)',
  },
  warningContainer: {
    display: 'flex',
    padding:
      'var(--utilities-size-0-500) var(--utilities-size-0-500) var(--utilities-size-0-500) var(--utilities-size-1-000)',
    alignItems: 'center',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid var(--border-danger-weak)',
    background: 'var(--surface-danger-tint)',
  },
  warningIcon: {
    width: 'var(--utilities-size-1-500)',
    height: 'var(--utilities-size-1-500)',
  },
  warningText: {
    color: 'var(--content-danger)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  buttonsСontainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    flex: '1 0 0',
  },
  buttonSectionText: {
    color: '#000',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  buttonSectionButton: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    cursor: 'pointer',
  },
  pauseButton: {
    color: 'var(--content-inverted)',
    background: 'var(--surface-accent)',
    '&:hover': {
      background: 'var(--surface-hover-accent-hover)',
    },
  },
  resumeButton: {
    color: 'var(--content-inverted)',
    background: 'var(--surface-success)',
    '&:hover': {
      background: 'var(--surface-hover-success-hover)',
    },
  },
  cancelButton: {
    border: '1px solid var(--border-danger-weak)',
    background: 'var(--surface-danger-tint)',
    color: 'var(--content-danger)',
    '&:hover': {
      border: '1px solid var(--border-hover-danger-weak-hover)',
      background: 'var(--surface-hover-danger-tint-hover)',
      color: 'var(--content-hover-danger-hover)',
    },
  },
  devider: {
    width: '1px',
    height: '128px',
    background: 'var(--border-default)',
  },
  load: {
    marginRight: '80px',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '240px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const statuses = ['active', 'trialing', 'past_due'];

// function PaperComponent(props) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }

const STATUS_ICONS = {
  active: CheckCircle,
  pending: Timer,
  trialling: CalendarX,
  paused: PauseCircle,
  canceled: CancelCircle,
  past_due: Warning,
  no_icon: null,
};

function StripeDialog({
  subsStatus,
  subsId,
  customerId,
  isPaused,
  isCanceled,
  isActive,
  onStateChange,
}) {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const [open, setOpen] = useState(false);
  const [customerPortalSessionUrl, setCustomerPortalSessionUrl] = useState('');
  const [initialOpen, setInitialOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredChip, setHoveredChip] = useState(null);
  const [hoveredClose, setHoveredClose] = useState(null);
  const [hoveredGo, setHoveredGo] = useState(null);
  const [hoveredGo2, setHoveredGo2] = useState(null);

  const STATUS_BG_COLORS = {
    active: 'var(--surface-success)',
    canceled: 'var(--surface-default)',
    trialling: 'var(--surface-success-tint)',
    past_due: 'var(--surface-danger)',
    default: 'var(--surface-tint-weak)',
  };

  const STATUS_COLORS = {
    active: 'var(--content-inverted)',
    canceled: 'var(--content-primary)',
    trialling: 'var(--content-success)',
    past_due: 'var(--content-inverted)',
    default: 'var(--content-primary-weak)',
  };

  const STATUS_BORDER_COLORS = {
    active: 'var(--surface-success)',
    canceled: 'var(--border-control-default)',
    trialling: 'var(--border-success-weak)',
    past_due: 'var(--surface-danger)',
    default: 'var(--surface-tint-weak)',
  };

  const STATUS_BG_COLORS_HOVERD = {
    active: 'var(--surface-hover-success-hover)',
    canceled: 'var(--surface-hover-default-hover)',
    trialling: 'var(--surface-hover-success-tint-hover)',
    past_due: 'var(--surface-hover-danger-hover)',
    default: 'var(--surface-tint-weak)',
  };

  const STATUS_COLORS_HOVERD = {
    active: 'var(--content-inverted)',
    canceled: 'var(--content-hover-primary-hover)',
    trialling: 'var(--content-hover-success-hover)',
    past_due: 'var(--content-inverted)',
    default: 'var(--content-primary-weak)',
  };

  const STATUS_BORDER_COLORS_HOVERD = {
    active: 'var(--surface-hover-success-hover)',
    canceled: 'var(--border-hover-accent-weak-hover)',
    trialling: 'var(--border-hover-success-weak-hover)',
    past_due: 'var(--surface-hover-danger-hover)',
    default: 'var(--surface-tint-weak)',
  };

  const getChipBgColor = status =>
    STATUS_BG_COLORS[status] || STATUS_BG_COLORS.default;
  const getChipColor = status => STATUS_COLORS[status] || STATUS_COLORS.default;
  const getChipBorderColor = status =>
    STATUS_BORDER_COLORS[status] || STATUS_BORDER_COLORS.default;

  const getChipBgColorHovered = status =>
    STATUS_BG_COLORS_HOVERD[status] || STATUS_BG_COLORS_HOVERD.default;
  const getChipColorHovered = status =>
    STATUS_COLORS_HOVERD[status] || STATUS_COLORS_HOVERD.default;
  const getChipBorderColorHovered = status =>
    STATUS_BORDER_COLORS_HOVERD[status] || STATUS_BORDER_COLORS_HOVERD.default;

  const IconComponent = STATUS_ICONS[subsStatus] || STATUS_ICONS.default;

  const initializeCustomerPortalSession = async () => {
    setIsLoading(true);
    const url = await createCustomerPortalSession(customerId);
    setCustomerPortalSessionUrl(url);
    setIsLoading(false);
    setInitialOpen(false);
  };

  const handleClickOpen = async () => {
    setOpen(true);
    if (initialOpen) {
      try {
        await initializeCustomerPortalSession();
      } catch (error) {
        console.error('Failed to initialize customer portal session: ', error);
        setIsLoading(false);
      }
    }
  };

  const goToCustomerPortal = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cancelSubscription = async () => {
    setOpen(false);
    await cancelSubscriptionById(subsId);
    onStateChange();
  };

  const resumeSubscription = async () => {
    setOpen(false);
    await resumeSubscriptionById(subsId);
    onStateChange();
  };

  const pauseSubscription = async () => {
    setOpen(false);
    await pauseSubscriptionById(subsId);
    onStateChange();
  };

  return (
    <>
      <Tooltip
        classes={tooltipClass}
        placement="top"
        title={
          subsStatus && !isCanceled
            ? 'Choose subscription action'
            : isCanceled
            ? 'Once the subscription is cancelled, it cannot be reinstated'
            : ''
        }
      >
        {subsStatus && !isCanceled && isPaused ? (
          <Box
            style={{
              backgroundColor: hoveredChip ? '#3A414E' : '#4B5363',
              color: '#FFFFFF',
              borderColor: '#4B5363',
              cursor: 'pointer',
            }}
            className={classes.chip}
            onClick={event => {
              event.stopPropagation();
              isPaused && handleClickOpen();
            }}
            onMouseEnter={() => setHoveredChip(true)}
            onMouseLeave={() => setHoveredChip(null)}
          >
            {IconComponent && (
              <IconComponent className={classes.icon} color={'#FFFFFF'} />
            )}
            {'Paused'}
          </Box>
        ) : (
          <Box
            style={{
              backgroundColor: hoveredChip
                ? getChipBgColorHovered(subsStatus)
                : getChipBgColor(subsStatus),
              color: hoveredChip
                ? getChipColorHovered(subsStatus)
                : getChipColor(subsStatus),
              borderColor: hoveredChip
                ? getChipBorderColorHovered(subsStatus)
                : getChipBorderColor(subsStatus),
              cursor: IconComponent && 'pointer',
            }}
            className={classes.chip}
            onClick={event => {
              event.stopPropagation();
              subsStatus && handleClickOpen();
            }}
            onMouseEnter={() => setHoveredChip(true)}
            onMouseLeave={() => setHoveredChip(null)}
          >
            {IconComponent && (
              <IconComponent
                className={classes.icon}
                color={`${
                  hoveredChip
                    ? getChipColorHovered(subsStatus)
                    : getChipColor(subsStatus)
                }`}
              />
            )}
            {!subsStatus
              ? 'No subscription'
              : subsStatus === 'past_due'
              ? 'Past due'
              : capitalizeFirstLetters(subsStatus)}
          </Box>
        )}
      </Tooltip>
      {isLoading ? (
        <Box className={`${classes.text} ${classes.load}`}>Loading...</Box>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          // PaperComponent={PaperComponent}
          // aria-labelledby="draggable-dialog-title"
          onClick={event => {
            event.stopPropagation();
          }}
          className={classes.dialog}
        >
          {isCanceled ? (
            <>
              <Box
                className={classes.dialogTitleContainer}
                // id="draggable-dialog-title"
              >
                <Box className={classes.dialogTitle}>
                  Check Your Billing Information
                </Box>
                <Box
                  className={classes.closeIcon}
                  onClick={handleClose}
                  onMouseEnter={() => setHoveredClose(true)}
                  onMouseLeave={() => setHoveredClose(null)}
                >
                  <Close color={hoveredClose ? '#5646E5' : '#2C313A'} />
                </Box>
              </Box>
              <Box className={classes.dialogContent}>
                {customerPortalSessionUrl && (
                  <>
                    <Box className={classes.section}>
                      <Box className={classes.text}>
                        Manage your billing and payment methods
                      </Box>
                      <Box
                        className={classes.linkContainer}
                        onMouseEnter={() => setHoveredGo(true)}
                        onMouseLeave={() => setHoveredGo(null)}
                      >
                        <a
                          className={classes.link}
                          href={`${customerPortalSessionUrl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Go To Stripe Customer Portal
                        </a>
                        <Box>
                          <LeadIcon2
                            className={classes.icon}
                            color={hoveredGo ? '5646E5' : '5E59F3'}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.section}>
                      <Box className={classes.text}>
                        Fell free to contact us if you have any doubts or
                        questions
                      </Box>
                      <Box
                        className={classes.linkContainer}
                        onMouseEnter={() => setHoveredGo2(true)}
                        onMouseLeave={() => setHoveredGo2(null)}
                      >
                        <NavLink to="/contacts" className={classes.link}>
                          Contact Support
                        </NavLink>
                        <Box>
                          <LeadIcon2
                            className={classes.icon}
                            color={hoveredGo2 ? '5646E5' : '5E59F3'}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          ) : (
            <>
              <Box
                className={classes.dialogTitleContainer}
                // id="draggable-dialog-title"
              >
                <Box className={classes.dialogTitle}>
                  Manage Your Subscription Options
                </Box>
                <Box
                  className={classes.closeIcon}
                  onClick={handleClose}
                  onMouseEnter={() => setHoveredClose(true)}
                  onMouseLeave={() => setHoveredClose(null)}
                >
                  <Close color={hoveredClose ? '#5646E5' : '#2C313A'} />
                </Box>
              </Box>
              <Box className={classes.dialogMainContainer}>
                <Box className={classes.warningContainer}>
                  <Warning className={classes.warningIcon} color={'#EF4444'} />
                  <Box className={classes.warningText}>
                    Cancellation is irreversible! Once the subscription is
                    cancelled, it cannot be reinstated.
                  </Box>
                </Box>
                <Box className={classes.buttonsСontainer}>
                  <Box className={classes.buttonSection}>
                    <>
                      {statuses.includes(subsStatus) &&
                        !isPaused &&
                        subsStatus !== 'past_due' && (
                          <>
                            <Box className={classes.buttonSectionText}>
                              Temporarily pause payments without canceling your
                              subscription:
                            </Box>
                            <Box
                              className={`${classes.buttonSectionButton} ${classes.pauseButton}`}
                              onClick={pauseSubscription}
                            >
                              Pause Payment Collection
                            </Box>
                          </>
                        )}
                      {isPaused && (
                        <>
                          <Box className={classes.buttonSectionText}>
                            Reactivate your subscription to access all benefits
                            and resume regular payments:
                          </Box>
                          <Box
                            className={`${classes.buttonSectionButton} ${classes.resumeButton}`}
                            onClick={resumeSubscription}
                          >
                            Resume Payment Collection
                          </Box>
                        </>
                      )}
                    </>
                  </Box>
                  <Box className={classes.devider}></Box>
                  <Box className={classes.buttonSection}>
                    <Box className={classes.buttonSectionText}>
                      Permanently cancel your subscription. This action is
                      irreversible:
                    </Box>
                    <Box
                      className={`${classes.buttonSectionButton} ${classes.cancelButton}`}
                      onClick={cancelSubscription}
                    >
                      Cancel Subscription
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.dialogContent}>
                {customerPortalSessionUrl && (
                  <>
                    <Box className={classes.section}>
                      <Box className={classes.text}>
                        Manage your billing and payment methods
                      </Box>
                      <Box
                        className={classes.linkContainer}
                        onMouseEnter={() => setHoveredGo(true)}
                        onMouseLeave={() => setHoveredGo(null)}
                      >
                        <a
                          className={classes.link}
                          href={`${customerPortalSessionUrl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Go To Stripe Customer Portal
                        </a>
                        <Box>
                          <LeadIcon2
                            className={classes.icon}
                            color={hoveredGo ? '5646E5' : '5E59F3'}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.section}>
                      <Box className={classes.text}>
                        Fell free to contact us if you have any doubts or
                        questions
                      </Box>
                      <Box
                        className={classes.linkContainer}
                        onMouseEnter={() => setHoveredGo2(true)}
                        onMouseLeave={() => setHoveredGo2(null)}
                      >
                        <NavLink to="/contacts" className={classes.link}>
                          Contact Support
                        </NavLink>
                        <Box>
                          <LeadIcon2
                            className={classes.icon}
                            color={hoveredGo2 ? '5646E5' : '5E59F3'}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
        </Dialog>
      )}
    </>
  );
}

export default StripeDialog;
