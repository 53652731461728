/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Tooltip, Box } from '@material-ui/core';
import { capitalize, cloneDeep } from 'lodash';

import { setTimeStep } from '~/store/timeStep';
import { RANGE_TYPE } from '../constants';

const useStyles = makeStyles(() => ({
  viewDurationSettersWrapper: {
    backgroundColor: 'var(--surface-default)',
    position: 'relative',
    display: 'flex',
    width: 'max-content',
    height: 'var(--utilities-size-1-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    marginLeft: 'var(--utilities-size-1-000)',
  },
  viewDurationSetter: {
    position: 'relative',
    boxShadow: '0px 1px 2px rgba(23, 28, 38, 0.05)',
    backgroundColor: 'var(--surface-default)',
    border: '1px solid rgba(23, 28, 38, 0.2)',
    boxSizing: 'border-box',
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'var(--utilities-size-1-500)',
    padding: 'var(--utilities-size-0-250) var(--utilities-size-0-375)',
    textAlign: 'center',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-xs-line)',
    color: 'var(--surface-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    '&:hover': {
      border: '1px solid rgba(70, 86, 229, 0.4)',
      color: 'var(--surface-hover-accent-hover)',
      cursor: 'pointer',
    },
    '&.active': {
      background: 'var(--surface-rude)',
      color: 'var(--surface-default)',
    },
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '240px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

export default function TimeStep({ visitsDateValue, onClick }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const selectedTimeStep = useSelector(state => state.timeStep.value);

  const handleTimeStepSelect = useCallback(
    step => {
      dispatch(
        setTimeStep(
          selectedTimeStep.map(item =>
            item.name === step
              ? { ...item, isSelected: true }
              : { ...item, isSelected: false },
          ),
        ),
      );

      // setCurrentStep(step);

      if (onClick) {
        onClick(step);
      }
    },
    [dispatch, onClick, selectedTimeStep],
  );

  const setTimeStepVisibleValue = useCallback(
    (step, value) => {
      dispatch(
        setTimeStep(
          selectedTimeStep.map(item =>
            item.name === step
              ? { ...item, visible: value }
              : { ...item, visible: !value },
          ),
        ),
      );
    },
    [dispatch, selectedTimeStep],
  );

  const setAllTimeStepVisible = useCallback(() => {
    dispatch(
      setTimeStep(selectedTimeStep.map(item => ({ ...item, visible: true }))),
    );
  }, [dispatch, selectedTimeStep]);

  useEffect(() => {
    if (visitsDateValue[1].diff(visitsDateValue[0], 'day') <= 70) {
      if (selectedTimeStep.some(s => !s.visible)) {
        setAllTimeStepVisible();
      }
    } else if (selectedTimeStep.find(t => t.name === RANGE_TYPE.DAY).visible) {
      const copy = cloneDeep(selectedTimeStep);
      copy.find(s => s.name === RANGE_TYPE.DAY).visible = false;

      if (onClick) {
        onClick(RANGE_TYPE.WEEK);
      }

      dispatch(
        setTimeStep(
          copy.map(item =>
            item.name === RANGE_TYPE.WEEK
              ? { ...item, isSelected: true }
              : { ...item, isSelected: false },
          ),
        ),
      );
    }
  }, [
    setTimeStepVisibleValue,
    handleTimeStepSelect,
    setAllTimeStepVisible,
    selectedTimeStep,
    onClick,
    dispatch,
    visitsDateValue,
  ]);

  const renderTimeStepBox = ({ name, isSelected, visible }) => (
    <Box
      role="button"
      key={name}
      onClick={() => visible && handleTimeStepSelect(name)}
      className={classes.viewDurationSetter}
      style={{
        color: isSelected && '#FFFFFF',
        background: isSelected ? '#4B5363' : '#FFFFFF',
        borderRadius:
          name === 'day'
            ? '8px 0 0 8px'
            : name === 'month'
            ? '0 8px 8px 0'
            : '0',
      }}
    >
      {capitalize(name)}
    </Box>
  );

  const renderTooltip = ({ name, isSelected }) => (
    <Tooltip
      key={name}
      classes={tooltipClass}
      placement="top"
      title="To view the daily chart, please choose a period that does not exceed 2 calendar months"
    >
      {renderTimeStepBox({ name, isSelected, visible: false })}
    </Tooltip>
  );

  return (
    <Box className={classes.viewDurationSettersWrapper}>
      {selectedTimeStep.map(({ name, isSelected, visible }) =>
        visible
          ? renderTimeStepBox({ name, isSelected, visible })
          : renderTooltip({ name, isSelected }),
      )}
    </Box>
  );
}
