/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Box, makeStyles } from '@material-ui/core';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
// import data from './data.json';

// import marker from 'leaflet/dist/images/marker-icon.png';
import marker from '../../../../../public/static/icons/seenic/map-pin-indigo.png';
// import marker2x from 'leaflet/dist/images/marker-icon-2x.png';

delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: marker,
//   iconUrl: marker,
//   shadowUrl: markerShadow,
//   iconSize: [40, 44],
//   iconAnchor: [21, 41],
//   shadowSize: [40, 44],
//   shadowAnchor: [21, 41],
//   popupAnchor: [0, -41]
// });

const customIcon = new L.Icon({
  iconUrl: marker,
  iconSize: [40, 44],
  iconAnchor: [21, 41],
  popupAnchor: [0, -41],
  shadowUrl: markerShadow,
  shadowSize: [41, 41],
  shadowAnchor: [12.5, 41],
});

const useStyles = makeStyles(() => ({
  fullScreenMap: {
    height: '400px',
    width: '840px',
    borderRadius: 'var(--utilities-border-radius-default)',
  },
}));

// const groupedData = data.data.reduce((acc, current) => {
//   const key = `${current.latitude},${current.longitude}`;
//   if (!acc[key]) {
//     acc[key] = { ...current };
//   } else {
//     acc[key].visitsCount += current.visitsCount;
//   }
//   return acc;
// }, {});

const HeatMap = ({ data }) => {
  const classes = useStyles();

  const groupedData = data.reduce((acc, current) => {
    const key = current.city.toLowerCase();
    if (!acc[key]) {
      acc[key] = { ...current };
    } else {
      acc[key].visitsCount += current.visitsCount;
    }
    return acc;
  }, {});

  return (
    <Box className={classes.heatMap}>
      <MapContainer
        className={classes.fullScreenMap}
        center={[40.0, -100.0]}
        zoom={4}
        minZoom={1}
        maxZoom={19}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        scrollWheelZoom
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        />
        <MarkerClusterGroup
          spiderfyOnMaxZoom={false}
          showCoverageOnHover={false}
        >
          {Object.values(groupedData).map(location => (
            <Marker
              key={location.locationId + location.id}
              position={[location.latitude, location.longitude]}
              icon={customIcon}
            >
              <Popup>
                City: {location.city}
                <br />
                Location Views: {location.visitsCount}
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </Box>
  );
};

HeatMap.propTypes = {
  data: PropTypes.array,
};

export default HeatMap;
