/* eslint-disable prettier/prettier */
import React, { useMemo, useState } from 'react';
import { Grid, makeStyles, Box, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';

import { ArrowLeft } from '~/views/private/billings/components/icons/ArrowLeft';
import { ArrowRight } from '~/views/private/billings/components/icons/ArrowRight';
import TourCard from '../components/TourCard';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    padding: 'var(--utilities-size-1-500)',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  cardContainer: {
    paddingTop: 'var(--utilities-size-1-500)',
  },
  header: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xl)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-xl-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  header2: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  grid: {
    flexBasis: '25%',
    minWidth: 200,
    flexGrow: 1,
    maxWidth: '25%',
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    height: 'var(--utilities-size-3-500)',
    backgroundColor: 'var(--surface-default)',
    marginTop: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  footerPart1: {
    display: 'flex',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-500)',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius:
      'var(--utilities-border-radius-default) var(--utilities-border-radius-none) var(--utilities-border-radius-none) var(--utilities-border-radius-default)',
    background: 'var(--surface-default)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
  },
  footerPart2: {
    display: 'flex',
    width: '160px',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderLeft: '1px solid var(--border-default)',
    background: 'var(--surface-default)',
  },
  pagination: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  footerPart3: {
    display: 'flex',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-500)',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    justifyContent: 'flex-Start',
    borderRadius:
      'var(--utilities-border-radius-none) var(--utilities-border-radius-default) var(--utilities-border-radius-default) var(--utilities-border-radius-none)',
    borderLeft: '1px solid var(--border-default)',
    background: 'var(--surface-default)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
  },
}));

const GalleryView = () => {
  const search = useSelector(state => state.gallery.search);
  const classes = useStyles();
  const stats = useSelector(state => state.companyStats.data);

  const [hoveredArrowLeft, setHoveredArrowLeft] = useState(null);
  const [hoveredArrowRight, setHoveredArrowRight] = useState(null);
  const [page, setPage] = useState(0);

  const itemsPerPage = 20;

  const filteredProperties = useMemo(() => {
    return stats.filter(property =>
      property.property_name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [stats, search]);

  const paginatedProperties = useMemo(() => {
    const startIndex = page * itemsPerPage;
    return filteredProperties.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredProperties, itemsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page className={classes.root} title="Gallery">
      <Header>
        <Box className={classes.chartHeaderContainer}>
          <Box className={classes.header}>All of your tours in one place!</Box>
          <Box className={classes.header2}>
            Choose any tour to view your data
          </Box>
        </Box>
      </Header>
      <Grid container spacing={1} className={classes.cardContainer}>
        {paginatedProperties.map(property => (
          <Grid
            key={property.property_id}
            item
            lg={4}
            md={6}
            xs={12}
            className={classes.grid}
          >
            <TourCard property={property} />
          </Grid>
        ))}
      </Grid>
      {filteredProperties.length > 20 ? (
        <Box className={classes.footer}>
          <IconButton
            className={classes.footerPart1}
            onMouseEnter={() => setHoveredArrowLeft(true)}
            onMouseLeave={() => setHoveredArrowLeft(null)}
            onClick={() => handleChangePage(null, page - 1)}
            disabled={page === 0}
          >
            <ArrowLeft
              color={
                page === 0
                  ? '#2C313A33'
                  : hoveredArrowLeft
                  ? '#5E59F3'
                  : '#2C313A'
              }
            />
          </IconButton>
          <Box className={classes.footerPart2}>
            <Box className={classes.pagination}>
              Page {page + 1} of{' '}
              {Math.ceil(filteredProperties.length / itemsPerPage)}
            </Box>
          </Box>
          <IconButton
            className={classes.footerPart3}
            onMouseEnter={() => setHoveredArrowRight(true)}
            onMouseLeave={() => setHoveredArrowRight(null)}
            onClick={() => handleChangePage(null, page + 1)}
            disabled={
              page >= Math.ceil(filteredProperties.length / itemsPerPage) - 1
            }
          >
            <ArrowRight
              color={
                page >= Math.ceil(filteredProperties.length / itemsPerPage) - 1
                  ? '#2C313A33'
                  : hoveredArrowRight
                  ? '#5E59F3'
                  : '#2C313A'
              }
            />
          </IconButton>
        </Box>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default GalleryView;
