import React from 'react';

export const Check = ({ className, color = '#2C313A' }) => {
  return (
    <svg
      className={`arrow-right ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="check">
        <path
          d="M21.5307 7.28068L9.53068 19.2807C9.46102 19.3504 9.3783 19.4057 9.28726 19.4435C9.19621 19.4812 9.09861 19.5006 9.00005 19.5006C8.90149 19.5006 8.80389 19.4812 8.71285 19.4435C8.6218 19.4057 8.53908 19.3504 8.46943 19.2807L3.21943 14.0307C3.0787 13.8899 2.99963 13.6991 2.99963 13.5001C2.99963 13.301 3.0787 13.1102 3.21943 12.9694C3.36016 12.8287 3.55103 12.7496 3.75005 12.7496C3.94907 12.7496 4.13995 12.8287 4.28068 12.9694L9.00005 17.6897L20.4694 6.21943C20.6102 6.07869 20.801 5.99963 21.0001 5.99963C21.1991 5.99963 21.3899 6.07869 21.5307 6.21943C21.6714 6.36016 21.7505 6.55103 21.7505 6.75005C21.7505 6.94907 21.6714 7.13995 21.5307 7.28068Z"
          fill={`${color}`}
        />
      </g>
    </svg>
  );
};
