/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { makeStyles, Tooltip, Box } from '@material-ui/core';

import ImageWithFallback from '~/components/shared/ImageWithFallback';
import { formatDateFromSeconds } from '~/utils/helper';
import { PlayCircle } from '~/views/private/gallery/components/icons/PlayCircle';
import { convertUnicodeString } from '~/views/private/overview/utils';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '222px',
    background: 'var(--surface-default)',
    boxShadow: 'var(--shadows-subtle)',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: 'var(--utilities-border-radius-default)',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
  },
  number: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  prop: {
    overflow: 'hidden',
    color: 'var(--content-secondary)',
    textOverflow: 'ellipsis',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-2xs)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-2xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  image: {
    width: '100%',
    height: 'var(--utilities-size-7-500)',
    borderTopLeftRadius: 'var(--utilities-border-radius-default)',
    borderTopRightRadius: 'var(--utilities-border-radius-default)',
    objectFit: 'cover',
  },
  goToLocation: {
    width: 'var(--utilities-size-1-500)',
    height: 'var(--utilities-size-1-500)',
  },
  paper: {
    padding: 'var(--utilities-size-1-000)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 'var(--utilities-size-0-500)',
    flex: '1',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
  },
  totalViewsContainer: {
    paddingRight: 'var(--utilities-size-0-500)',
    borderRight: '1px solid var(--border-default)',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  multilocationName: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-lg-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  skeletonContainer: {
    height: '222px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    width: '116px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const BASE_URL = process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT;

function LocationCard({ location }) {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();

  const [isLoading, setIsLoading] = useState(null);
  const [hoveredStatus, setHoveredStatus] = useState(null);
  const [hoveredPlayIcon, setHoveredPlayIcon] = useState(null);
  const navigate = useNavigate();

  return (
    <>
      {!isLoading && (
        <Box className={classes.skeletonContainer}>
          <Skeleton height={164} />
          <Skeleton height={57} />
        </Box>
      )}
      <Box
        className={classes.root}
        style={{ display: isLoading ? 'flex' : 'none' }}
        onMouseEnter={() => setHoveredStatus(true)}
        onMouseLeave={() => setHoveredStatus(null)}
        onClick={() => {
          return navigate(`/overview/location/${location.location_id}`);
        }}
      >
        <ImageWithFallback
          className={classes.image}
          src={`${BASE_URL}/locations/${location.location_id}/preview.jpg`}
          fallbackSrc={`/static/images/no-img-placeholder.png`}
          alt="Preview image"
          isLoad={isLoading}
          setIsLoad={setIsLoading}
        />
        <Box className={classes.paper}>
          <Box className={classes.cardHeader}>
            <Box
              className={`${classes.ellipsis} ${classes.multilocationName}`}
              style={{
                color: hoveredStatus
                  ? 'var(--content-hover-primary-hover)'
                  : 'var(--content-primary)',
              }}
            >
              {convertUnicodeString(location.location_name)}
            </Box>
            <Tooltip
              classes={tooltipClass}
              placement="top"
              title={'Launch the tour'}
            >
              <a
                href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${location.location_id}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={e => e.stopPropagation()}
                onMouseEnter={() => setHoveredPlayIcon(true)}
                onMouseLeave={() => setHoveredPlayIcon(null)}
              >
                <PlayCircle
                  className={classes.goToLocation}
                  color={hoveredPlayIcon ? '5646E5' : '2C313A'}
                />
              </a>
            </Tooltip>
          </Box>
          <Box className={classes.cardContent}>
            <Box className={classes.totalViewsContainer}>
              <Box className={classes.prop}>Total views</Box>
              <Box className={classes.number}>{location.totalViews || 0}</Box>
            </Box>
            <Box className={classes.totalViewsContainer}>
              <Box className={classes.prop}>Total Duration</Box>
              <Box className={classes.number}>
                {formatDateFromSeconds(location.totalDuration) || '00:00'}
              </Box>
            </Box>
            <Box>
              <Box className={classes.prop}>Conversions</Box>
              <Box className={classes.number}>
                {location.totalConversion || 0}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LocationCard;
