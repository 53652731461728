import React from 'react';

export const Squares = ({ className, color = '#2C313A' }) => {
  return (
    <svg
      className={`squares ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="squares">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 5C5.89544 5 5 5.89544 5 7V13C5 14.1045 5.89543 15 7 15H13C14.1046 15 15 14.1046 15 13V7C15 5.89543 14.1045 5 13 5H7ZM7 7H13V13H7V7Z"
          fill={`${color}`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 5C17.8955 5 17 5.89543 17 7V13C17 14.1046 17.8954 15 19 15H25C26.1046 15 27 14.1046 27 13V7C27 5.89543 26.1045 5 25 5H19ZM19 7H25V13H19V7Z"
          fill={`${color}`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 19C5 17.8955 5.89543 17 7 17H13C14.1046 17 15 17.8954 15 19V25C15 26.1046 14.1046 27 13 27H7C5.89543 27 5 26.1045 5 25V19ZM13 19H7V25H13V19Z"
          fill={`${color}`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 17C17.8954 17 17 17.8954 17 19V25C17 26.1046 17.8954 27 19 27H25C26.1046 27 27 26.1046 27 25V19C27 17.8954 26.1046 17 25 17H19ZM19 19H25V25H19V19Z"
          fill={`${color}`}
        />
      </g>
    </svg>
  );
};
