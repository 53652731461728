import React from 'react';

export const Reset = ({ className, color = '2C313A' }) => {
  return (
    <svg
      className={`reset-icon ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="reset">
        <path
          d="M21 11.9994C21.0002 14.3655 20.0686 16.6366 18.4069 18.321C16.7451 20.0054 14.4869 20.9676 12.1209 20.9994H12C9.70147 21.0051 7.48901 20.1255 5.82187 18.5432C5.75028 18.4754 5.69273 18.3943 5.6525 18.3043C5.61227 18.2144 5.59016 18.1174 5.58741 18.0189C5.58467 17.9204 5.60136 17.8223 5.63652 17.7302C5.67168 17.6382 5.72463 17.554 5.79234 17.4824C5.86006 17.4108 5.94121 17.3532 6.03116 17.313C6.12112 17.2728 6.21812 17.2507 6.31662 17.2479C6.41512 17.2452 6.5132 17.2619 6.60525 17.297C6.69731 17.3322 6.78153 17.3851 6.85312 17.4528C7.92544 18.4641 9.27195 19.137 10.7245 19.3875C12.177 19.6379 13.6711 19.4548 15.0202 18.861C16.3692 18.2673 17.5134 17.2892 18.3098 16.0489C19.1062 14.8086 19.5195 13.3612 19.4981 11.8874C19.4766 10.4136 19.0214 8.97882 18.1892 7.76224C17.3571 6.54567 16.1849 5.60126 14.8192 5.047C13.4534 4.49273 11.9546 4.35318 10.51 4.64579C9.06537 4.93839 7.739 5.65017 6.69656 6.69221C6.6889 6.7005 6.68076 6.70832 6.67219 6.71565L4.18031 8.9994H6.75C6.94891 8.9994 7.13968 9.07842 7.28033 9.21907C7.42098 9.35972 7.5 9.55049 7.5 9.7494C7.5 9.94831 7.42098 10.1391 7.28033 10.2797C7.13968 10.4204 6.94891 10.4994 6.75 10.4994H2.25C2.05109 10.4994 1.86032 10.4204 1.71967 10.2797C1.57902 10.1391 1.5 9.94831 1.5 9.7494V5.2494C1.5 5.05049 1.57902 4.85972 1.71967 4.71907C1.86032 4.57842 2.05109 4.4994 2.25 4.4994C2.44891 4.4994 2.63968 4.57842 2.78033 4.71907C2.92098 4.85972 3 5.05049 3 5.2494V8.04315L5.64844 5.6244C6.90842 4.36931 8.512 3.51565 10.2567 3.17118C12.0015 2.82671 13.8092 3.00687 15.4516 3.68892C17.0941 4.37097 18.4976 5.52434 19.4851 7.00341C20.4726 8.48249 20.9997 10.221 21 11.9994Z"
          fill={`#${color}`}
        />
      </g>
    </svg>
  );
};
