import React from 'react';

export const Build = ({ className, color = '#2C313A' }) => {
  return (
    <svg
      className={`build ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="build">
        <path
          d="M22.8601 19.5H22.1101V6.75C22.1101 6.55109 22.0311 6.36032 21.8904 6.21967C21.7498 6.07902 21.559 6 21.3601 6H17.6101V3.75C17.6101 3.55109 17.5311 3.36032 17.3904 3.21967C17.2498 3.07902 17.059 3 16.8601 3H7.86011C7.66119 3 7.47043 3.07902 7.32978 3.21967C7.18913 3.36032 7.11011 3.55109 7.11011 3.75V9H3.36011C3.1612 9 2.97043 9.07902 2.82978 9.21967C2.68912 9.36032 2.61011 9.55109 2.61011 9.75V19.5H1.86011C1.6612 19.5 1.47043 19.579 1.32978 19.7197C1.18913 19.8603 1.11011 20.0511 1.11011 20.25C1.11011 20.4489 1.18913 20.6397 1.32978 20.7803C1.47043 20.921 1.6612 21 1.86011 21H22.8601C23.059 21 23.2498 20.921 23.3904 20.7803C23.5311 20.6397 23.6101 20.4489 23.6101 20.25C23.6101 20.0511 23.5311 19.8603 23.3904 19.7197C23.2498 19.579 23.059 19.5 22.8601 19.5ZM4.11011 10.5H7.86011C8.05902 10.5 8.24978 10.421 8.39044 10.2803C8.53109 10.1397 8.61011 9.94891 8.61011 9.75V4.5H16.1101V6.75C16.1101 6.94891 16.1891 7.13968 16.3298 7.28033C16.4704 7.42098 16.6612 7.5 16.8601 7.5H20.6101V19.5H14.6101V14.75C14.6101 14.5511 14.5311 14.3603 14.3904 14.2197C14.2498 14.079 14.059 14 13.8601 14H10.8601C10.6612 14 10.4704 14.079 10.3298 14.2197C10.1891 14.3603 10.1101 14.5511 10.1101 14.75V19.5H4.11011V10.5ZM13.1101 19.5H11.6101V15.5H13.1101V19.5Z"
          fill={`${color}`}
        />
      </g>
    </svg>
  );
};
