/* eslint-disable prettier/prettier */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Box, IconButton } from '@material-ui/core';

import { ArrowLeft } from '~/views/private/billings/components/icons/ArrowLeft';
import { ArrowRight } from '~/views/private/billings/components/icons/ArrowRight';
import LocationCard from '../components/LocationCard';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
  },
  header: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xl)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-xl-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  header2: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  grid: {
    flexBasis: '25%',
    minWidth: '200px',
    flexGrow: '1',
    maxWidth: '25%',
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    height: 'var(--utilities-size-3-500)',
    backgroundColor: 'var(--surface-default)',
    marginTop: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  footerPart1: {
    display: 'flex',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-500)',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius:
      'var(--utilities-border-radius-default) var(--utilities-border-radius-none) var(--utilities-border-radius-none) var(--utilities-border-radius-default)',
    background: 'var(--surface-default)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
  },
  footerPart2: {
    display: 'flex',
    width: '160px',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderLeft: '1px solid var(--border-default)',
    background: 'var(--surface-default)',
  },
  pagination: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  footerPart3: {
    display: 'flex',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-500)',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    justifyContent: 'flex-Start',
    borderRadius:
      'var(--utilities-border-radius-none) var(--utilities-border-radius-default) var(--utilities-border-radius-default) var(--utilities-border-radius-none)',
    borderLeft: '1px solid var(--border-default)',
    background: 'var(--surface-default)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
  },
}));

const LocationsGalleryView = ({ locations }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [hoveredArrowLeft, setHoveredArrowLeft] = useState(null);
  const [hoveredArrowRight, setHoveredArrowRight] = useState(null);
  const itemsPerPage = 12;

  const paginatedLocations = useMemo(() => {
    const startIndex = page * itemsPerPage;
    return locations.slice(startIndex, startIndex + itemsPerPage);
  }, [locations, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={1}>
        {paginatedLocations.map(location => (
          <Grid
            key={location.location_id}
            item
            lg={4}
            md={6}
            xs={12}
            className={classes.grid}
          >
            <LocationCard location={location} />
          </Grid>
        ))}
      </Grid>
      {locations.length > itemsPerPage ? (
        <Box className={classes.footer}>
          <IconButton
            className={classes.footerPart1}
            onMouseEnter={() => setHoveredArrowLeft(true)}
            onMouseLeave={() => setHoveredArrowLeft(null)}
            onClick={() => handleChangePage(null, page - 1)}
            disabled={page === 0}
          >
            <ArrowLeft
              color={
                page === 0
                  ? '#2C313A33'
                  : hoveredArrowLeft
                  ? '#5E59F3'
                  : '#2C313A'
              }
            />
          </IconButton>
          <Box className={classes.footerPart2}>
            <Box className={classes.pagination}>
              Page {page + 1} of {Math.ceil(locations.length / itemsPerPage)}
            </Box>
          </Box>
          <IconButton
            className={classes.footerPart3}
            onMouseEnter={() => setHoveredArrowRight(true)}
            onMouseLeave={() => setHoveredArrowRight(null)}
            onClick={() => handleChangePage(null, page + 1)}
            disabled={page >= Math.ceil(locations.length / itemsPerPage) - 1}
          >
            <ArrowRight
              color={
                page >= Math.ceil(locations.length / itemsPerPage) - 1
                  ? '#2C313A33'
                  : hoveredArrowRight
                  ? '#5E59F3'
                  : '#2C313A'
              }
            />
          </IconButton>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

LocationsGalleryView.propTypes = {
  locations: PropTypes.array,
};

export default LocationsGalleryView;
