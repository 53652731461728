import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';

import { setRange, setUseCustomRange } from '~/store/datePicker';
import { Reset } from '~/views/private/overview/Components/icons/Reset';
import CustomDateRangePicker from './DateRangePicker';

const useStyles = makeStyles(() => ({
  resetButton: {
    position: 'relative',
    boxShadow: 'var(--shadows-subtle)',
    borderRadius: 'var(--utilities-border-radius-default)',
    backgroundColor: 'var(--surface-default)',
    border: '1px solid rgba(23, 28, 38, 0.2)',
    boxSizing: 'border-box',
    width: '65px',
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 6px',
    gap: 'var(--utilities-size-0-250)',
    height: 'var(--utilities-size-1-500)',
    textAlign: 'center',
    fontSize: 'var(--typography-font-size-xs)',
    color: 'var(--surface-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    textTransform: 'none',
    '&:hover': {
      border: '1px solid rgba(70, 86, 229, 0.4)',
      color: 'var(--surface-hover-accent-hover)',
      backgroundColor: 'var(--surface-default)',
    },
  },
  resetIcon: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    position: 'relative',
    overflow: 'hidden',
    flexShrink: '0',
    marginRight: 'var(--utilities-size-0-250)',
  },
}));

const MultiDateRangePicker = ({ resetDatePicker }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const range = useSelector(state => state.datePicker.range);
  const initialRange = useSelector(state => state.datePicker.initialRange);
  const [arrowIconColor, setArrowIconColor] = useState(null);
  const handleDateChange = useCallback(
    newDate => {
      dispatch(setRange(newDate));
    },
    [dispatch],
  );

  const resetHandler = useCallback(() => {
    dispatch(setRange(initialRange));
    dispatch(setUseCustomRange(false));
  }, [dispatch, initialRange]);

  const openHandler = useCallback(() => {
    dispatch(setUseCustomRange(true));
  }, [dispatch]);

  return (
    <div className="flex gap-x-4">
      <CustomDateRangePicker
        value={range}
        onChange={handleDateChange}
        onOpen={openHandler}
      />
      <Button
        className={classes.resetButton}
        type="button"
        onMouseEnter={() => setArrowIconColor('5E59F3')}
        onMouseLeave={() => setArrowIconColor(null)}
        onClick={() => {
          resetHandler();
          if (resetDatePicker) resetDatePicker();
        }}
      >
        <Reset
          className={classes.resetIcon}
          color={arrowIconColor || '2C313A'}
        />
        <Box className={classes.textWrapper12}>Reset</Box>
      </Button>
    </div>
  );
};

export default MultiDateRangePicker;
