import React from 'react';

export const PauseCircle = ({ className, color = '#2C313A' }) => {
  return (
    <svg
      className={`pause-circle ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="pause-circle">
        <path
          d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM10.5 9V15C10.5 15.1989 10.421 15.3897 10.2803 15.5303C10.1397 15.671 9.94892 15.75 9.75 15.75C9.55109 15.75 9.36033 15.671 9.21967 15.5303C9.07902 15.3897 9 15.1989 9 15V9C9 8.80109 9.07902 8.61032 9.21967 8.46967C9.36033 8.32902 9.55109 8.25 9.75 8.25C9.94892 8.25 10.1397 8.32902 10.2803 8.46967C10.421 8.61032 10.5 8.80109 10.5 9ZM15 9V15C15 15.1989 14.921 15.3897 14.7803 15.5303C14.6397 15.671 14.4489 15.75 14.25 15.75C14.0511 15.75 13.8603 15.671 13.7197 15.5303C13.579 15.3897 13.5 15.1989 13.5 15V9C13.5 8.80109 13.579 8.61032 13.7197 8.46967C13.8603 8.32902 14.0511 8.25 14.25 8.25C14.4489 8.25 14.6397 8.32902 14.7803 8.46967C14.921 8.61032 15 8.80109 15 9Z"
          fill={`${color}`}
        />
      </g>
    </svg>
  );
};
