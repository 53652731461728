import { createSlice } from '@reduxjs/toolkit';

export const companyStats = createSlice({
  name: 'companyStats',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCompanyStats: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setCompanyStats, setLoading, setError } = companyStats.actions;
export default companyStats.reducer;
