import React, { useState } from 'react';

import { Tooltip, makeStyles, Box } from '@material-ui/core';
import { capitalize } from 'lodash';
import { capitalizeFirstLetters } from '~/views/private/billings/utils';
import { Warning } from '~/views/private/overview/Components/icons/Warning';
import { CheckCircle } from '~/views/private/overview/Components/icons/CheckCircle';
import { Timer } from '~/views/private/overview/Components/icons/Timer';

const useStyles = makeStyles(() => ({
  chip: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-000)',
    width: '148px',
    color: 'var(--content-inverted)',
    border: '1px solid var(--content-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  icon: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    marginRight: 'var(--utilities-size-0-500)',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '240px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const STATUS_ICONS = {
  active: CheckCircle,
  pending: Timer,
  past_due: Warning,
  default: Warning,
};

function QuickBooksStatusChip({
  invoiceStatus,
  qbRecurringTransactionId,
  isAdmin,
}) {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const [hoveredChip, setHoveredChip] = useState(null);
  const IconComponent = STATUS_ICONS[invoiceStatus] || STATUS_ICONS.default;

  const STATUS_BG_COLORS = {
    active: 'var(--surface-success)',
    pending: 'var(--surface-danger-tint)',
    past_due: 'var(--surface-danger)',
    default: 'var(--surface-tint-weak)',
  };

  const STATUS_COLORS = {
    active: 'var(--content-inverted)',
    pending: 'var(--content-danger)',
    past_due: 'var(--content-inverted)',
    default: 'var(--content-primary-weak)',
  };

  const STATUS_BORDER_COLORS = {
    active: 'var(--surface-success)',
    pending: 'var(--border-danger-weak)',
    past_due: 'var(--surface-danger)',
    default: 'var(--surface-tint-weak)',
  };

  const STATUS_BG_COLORS_HOVERD = {
    active: 'var(--surface-hover-success-hover)',
    pending: 'var(--surface-hover-danger-tint-hover)',
    past_due: 'var(--surface-hover-danger-hover)',
    default: 'var(--surface-tint-weak)',
  };

  const STATUS_COLORS_HOVERD = {
    active: 'var(--content-inverted)',
    pending: 'var(--content-hover-danger-hover)',
    past_due: 'var(--content-inverted)',
    default: 'var(--content-primary-weak)',
  };

  const STATUS_BORDER_COLORS_HOVERD = {
    active: 'var(--surface-hover-success-hover)',
    pending: 'var(--border-hover-danger-weak-hover)',
    past_due: 'var(--surface-hover-danger-hover)',
    default: 'var(--surface-tint-weak)',
  };

  const getChipBgColor = status =>
    STATUS_BG_COLORS[status] || STATUS_BG_COLORS.default;
  const getChipColor = status => STATUS_COLORS[status] || STATUS_COLORS.default;
  const getChipBorderColor = status =>
    STATUS_BORDER_COLORS[status] || STATUS_BORDER_COLORS.default;

  const getChipBgColorHovered = status =>
    STATUS_BG_COLORS_HOVERD[status] || STATUS_BG_COLORS_HOVERD.default;
  const getChipColorHovered = status =>
    STATUS_COLORS_HOVERD[status] || STATUS_COLORS_HOVERD.default;
  const getChipBorderColorHovered = status =>
    STATUS_BORDER_COLORS_HOVERD[status] || STATUS_BORDER_COLORS_HOVERD.default;

  const handleClick = e => e.stopPropagation();
  const getEnv = () =>
    process.env.NODE_ENV === 'production' ? 'app' : 'sandbox';

  return (
    <>
      {isAdmin ? (
        <Tooltip
          classes={tooltipClass}
          placement="top"
          title={'Click to open recurring transaction link'}
        >
          <a
            href={`https://${getEnv()}.qbo.intuit.com/app/recurringinvoice?templateAction=GET&txnId=${qbRecurringTransactionId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              style={{
                backgroundColor: hoveredChip
                  ? getChipBgColorHovered(invoiceStatus)
                  : getChipBgColor(invoiceStatus),
                color: hoveredChip
                  ? getChipColorHovered(invoiceStatus)
                  : getChipColor(invoiceStatus),
                borderColor: hoveredChip
                  ? getChipBorderColorHovered(invoiceStatus)
                  : getChipBorderColor(invoiceStatus),
              }}
              className={classes.chip}
              onClick={handleClick}
              onMouseEnter={() => setHoveredChip(true)}
              onMouseLeave={() => setHoveredChip(null)}
            >
              <IconComponent
                className={classes.icon}
                color={`${
                  hoveredChip
                    ? getChipColorHovered(invoiceStatus)
                    : getChipColor(invoiceStatus)
                }`}
              />
              {invoiceStatus === 'past_due'
                ? 'Past due'
                : capitalize(invoiceStatus)}
            </Box>
          </a>
        </Tooltip>
      ) : (
        <Box
          style={{
            backgroundColor: hoveredChip
              ? getChipBgColorHovered(invoiceStatus)
              : getChipBgColor(invoiceStatus),
            color: hoveredChip
              ? getChipColorHovered(invoiceStatus)
              : getChipColor(invoiceStatus),
            borderColor: hoveredChip
              ? getChipBorderColorHovered(invoiceStatus)
              : getChipBorderColor(invoiceStatus),
          }}
          className={classes.chip}
          onClick={handleClick}
          onMouseEnter={() => setHoveredChip(true)}
          onMouseLeave={() => setHoveredChip(null)}
        >
          <IconComponent
            className={classes.icon}
            color={`${
              hoveredChip
                ? getChipColorHovered(invoiceStatus)
                : getChipColor(invoiceStatus)
            }`}
          />
          {invoiceStatus === 'past_due'
            ? 'Past due'
            : capitalize(invoiceStatus)}
        </Box>
      )}
    </>
  );
}

export default QuickBooksStatusChip;
