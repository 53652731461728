import React from 'react';

export const ArrowRight = ({ className, color = '#2C313A' }) => {
  return (
    <svg
      className={`arrow-right ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-right">
        <path
          d="M20.7803 12.5306L14.0303 19.2806C13.8896 19.4213 13.6987 19.5003 13.4997 19.5003C13.3007 19.5003 13.1098 19.4213 12.9691 19.2806C12.8283 19.1398 12.7493 18.949 12.7493 18.7499C12.7493 18.5509 12.8283 18.36 12.9691 18.2193L18.4394 12.7499H3.74969C3.55078 12.7499 3.36002 12.6709 3.21936 12.5303C3.07871 12.3896 2.99969 12.1988 2.99969 11.9999C2.99969 11.801 3.07871 11.6103 3.21936 11.4696C3.36002 11.3289 3.55078 11.2499 3.74969 11.2499H18.4394L12.9691 5.78055C12.8283 5.63982 12.7493 5.44895 12.7493 5.24993C12.7493 5.05091 12.8283 4.86003 12.9691 4.7193C13.1098 4.57857 13.3007 4.49951 13.4997 4.49951C13.6987 4.49951 13.8896 4.57857 14.0303 4.7193L20.7803 11.4693C20.8501 11.539 20.9054 11.6217 20.9431 11.7127C20.9809 11.8038 21.0003 11.9014 21.0003 11.9999C21.0003 12.0985 20.9809 12.1961 20.9431 12.2871C20.9054 12.3782 20.8501 12.4609 20.7803 12.5306Z"
          fill={`${color}`}
        />
      </g>
    </svg>
  );
};
