/* eslint-disable prettier/prettier */
import { capitalize } from 'lodash';

export function sortByFieldName(fieldName) {
  return (a, b) => (a[fieldName] > b[fieldName] ? 1 : -1);
}

export function dateConverter(dateString) {
  const date = new Date(dateString);
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${month}/${day}/${year}`;
}

export function capitalizeFirstLetters(str) {
  return str
    .split(' ')
    .map(word => capitalize(word))
    .join(' ');
}
