import React from 'react';

export const Map = ({ className, color = '#2C313A' }) => {
  return (
    <svg
      className={`map ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="map">
        <path
          d="M11.7575 4.02986C11.989 3.97196 12.2337 3.99882 12.4472 4.10557L20.1163 7.94014L27.7575 6.02986C28.0562 5.95517 28.3727 6.02229 28.6154 6.2118C28.8581 6.4013 29 6.69207 29 7V25C29 25.4589 28.6877 25.8589 28.2425 25.9701L20.2425 27.9701C20.011 28.028 19.7663 28.0012 19.5528 27.8944L11.8837 24.0599L4.24254 25.9701C3.94379 26.0448 3.6273 25.9777 3.38459 25.7882C3.14187 25.5987 3 25.3079 3 25V7C3 6.54113 3.3123 6.14115 3.75746 6.02986L11.7575 4.02986ZM13 22.382L19 25.382V9.61804L13 6.61804V22.382ZM11 6.28078V22.2192L5 23.7192V7.78078L11 6.28078ZM21 9.78078V25.7192L27 24.2192V8.28078L21 9.78078Z"
          fill={`${color}`}
        />
      </g>
    </svg>
  );
};
