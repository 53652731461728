import React from 'react';

export const Cube = ({ className, color = '#2C313A' }) => {
  return (
    <svg
      className={`cube ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="cube">
        <path
          d="M27.96 8.27024L16.96 2.25149C16.6661 2.08909 16.3358 2.00391 16 2.00391C15.6642 2.00391 15.3339 2.08909 15.04 2.25149L4.04 8.27274C3.72586 8.44462 3.46363 8.69769 3.28069 9.00552C3.09775 9.31335 3.00081 9.66465 3 10.0227V21.9777C3.00081 22.3358 3.09775 22.6871 3.28069 22.995C3.46363 23.3028 3.72586 23.5559 4.04 23.7277L15.04 29.749C15.3339 29.9114 15.6642 29.9966 16 29.9966C16.3358 29.9966 16.6661 29.9114 16.96 29.749L27.96 23.7277C28.2741 23.5559 28.5364 23.3028 28.7193 22.995C28.9023 22.6871 28.9992 22.3358 29 21.9777V10.024C28.9999 9.66526 28.9032 9.31318 28.7203 9.00463C28.5373 8.69608 28.2747 8.44243 27.96 8.27024ZM16 4.00149L26.0425 9.50149L16 15.0015L5.9575 9.50149L16 4.00149ZM5 11.2515L15 16.724V27.4477L5 21.979V11.2515ZM17 27.4477V16.729L27 11.2515V21.974L17 27.4477Z"
          fill={`${color}`}
        />
      </g>
    </svg>
  );
};
