import React from 'react';

export const LeadIcon = ({ onClick, className, color = '6B7280' }) => {
  return (
    <svg
      onClick={onClick}
      className={`lead-icon ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM10.3538 8.14625C10.4002 8.19269 10.4371 8.24783 10.4623 8.30853C10.4874 8.36923 10.5004 8.43429 10.5004 8.5C10.5004 8.56571 10.4874 8.63077 10.4623 8.69147C10.4371 8.75217 10.4002 8.80731 10.3538 8.85375L8.35375 10.8538C8.30732 10.9002 8.25217 10.9371 8.19147 10.9623C8.13077 10.9874 8.06571 11.0004 8 11.0004C7.9343 11.0004 7.86923 10.9874 7.80853 10.9623C7.74783 10.9371 7.69269 10.9002 7.64625 10.8538L5.64625 8.85375C5.55243 8.75993 5.49972 8.63268 5.49972 8.5C5.49972 8.36732 5.55243 8.24007 5.64625 8.14625C5.74007 8.05243 5.86732 7.99972 6 7.99972C6.13269 7.99972 6.25993 8.05243 6.35375 8.14625L7.5 9.29313V5.5C7.5 5.36739 7.55268 5.24022 7.64645 5.14645C7.74022 5.05268 7.86739 5 8 5C8.13261 5 8.25979 5.05268 8.35356 5.14645C8.44732 5.24022 8.5 5.36739 8.5 5.5V9.29313L9.64625 8.14625C9.69269 8.09976 9.74783 8.06288 9.80853 8.03772C9.86923 8.01256 9.93429 7.99961 10 7.99961C10.0657 7.99961 10.1308 8.01256 10.1915 8.03772C10.2522 8.06288 10.3073 8.09976 10.3538 8.14625Z"
        fill={`#${color}`}
      />
    </svg>
  );
};
