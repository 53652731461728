/* eslint-disable prettier/prettier */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import { useAsync } from 'react-use';

import moment from 'moment';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Box, IconButton, makeStyles, Tooltip } from '@material-ui/core';

import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import {
  getPropertyOverallStats,
  getPropertyViewsChartStats,
  getPropertyHeatmapStats,
} from '~/services/api';

import {
  calculateChartSize,
  calculateEventsInIntervals,
  calculateImgSize,
  filterStatsByDate,
  formatLabels,
} from '~/views/private/overview/utils';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';
import { CardStat } from '~/views/private/overview/Components/CardStat';
import { trySetRange } from '~/store/datePicker';

import {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
} from '~/store/visitsTooltip';

import { ArrowLeft } from '~/views/private/billings/components/icons/ArrowLeft';
import { Panorama } from '~/views/private/overview/Components/icons/Panorama';
import { Cube } from '~/views/private/overview/Components/icons/Cube';
import { Build } from '~/views/private/overview/Components/icons/Build';
import { Lotus } from '~/views/private/overview/Components/icons/Lotus';
import { Map } from '~/views/private/overview/Components/icons/Map';
import { Squares } from '~/views/private/overview/Components/icons/Squares';
import { Check } from '~/views/private/overview/Components/icons/Check';
import { LeadIcon2 } from '~/views/private/overview/Components/icons/LeadIcon2';
import { Download } from '~/views/private/overview/Components/icons/Download';
import HeatMap from '~/views/private/overview/Components/HeatMap';
import { ArrowRight } from '~/views/private/billings/components/icons/ArrowRight';
import { MultiDateRangePicker, TimeStep, GraphTooltip } from './Components';
import { TOOLTIP_TYPE } from './constants';
import data from './Components/dataUnits.json';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    padding: 'var(--utilities-size-1-500)',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    padding:
      'var(--utilities-size-none) var(--utilities-size-none) var(--utilities-size-0-750) var(--utilities-size-none)',
    alignItems: 'center',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--border-default)',
  },
  headerTextSection: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    flex: '1 0 0',
  },
  headerButtonsSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
  },
  backButton: {
    display: 'flex',
    width: '52px',
    height: '52px',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    background: 'var(--surface-tint-weak)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-tint-weak-hover)',
    },
  },
  backButtonIcon: {
    width: 'var(--utilities-size-1-250)',
    height: 'var(--utilities-size-1-250)',
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
  },
  headerTitleText: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xl)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-xl-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  headerSubTitleText: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  virtualTourButton: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid var(--border-accent-weak)',
    background: 'var(--surface-accent-tint)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid var(--border-hover-accent-weak-hover)',
      background: 'var(--surface-hover-accent-tint-hover)',
    },
  },
  buttonIcon: {
    width: 'var(--utilities-size-1-250)',
    height: 'var(--utilities-size-1-250)',
  },
  virtualTourButtonText: {
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  explorerButton: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    background: 'var(--surface-accent)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-accent-hover)',
    },
  },
  explorerButtonText: {
    color: 'var(--content-inverted)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  leadIcon2: {
    height: 'var(--utilities-size-1-000)',
    width: 'var(--utilities-size-1-000)',
  },
  infoCardContainer: {
    alignSelf: 'stretch',
    paddingTop: 'var(--utilities-size-1-500)',
  },
  infoCardsRow: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
  },
  cardStat: {
    flex: '1 !important',
    flexGrow: '1 !important',
    justifyContent: 'center !important',
    width: 'unset !important',
  },
  designComponentInstanceNode: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-lg-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  chartHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 0 20px 0',
  },
  sectionHeader: {
    color: 'var(--content-primary)',
    fontSize: 'var(--typography-font-size-md)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  downloadIconContainer: {
    position: 'relative',
    boxShadow: '0px 1px 2px rgba(23, 28, 38, 0.05)',
    borderRadius: 'var(--utilities-border-radius-default)',
    backgroundColor: 'var(--surface-default)',
    border: '1px solid rgba(23, 28, 38, 0.2)',
    boxSizing: 'border-box',
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 6px',
    height: 'var(--utilities-size-1-500)',
    marginLeft: 'var(--utilities-size-1-000)',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgba(70, 86, 229, 0.4)',
    },
  },
  downloadIcon: {
    position: 'relative',
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    overflow: 'hidden',
    flexShrink: 0,
  },
  container: {
    height: '370px',
  },
  heatMapContainer: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-500)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  heatMapContent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-7-500)',
    alignSelf: 'stretch',
  },
  heatMapTable: {
    display: 'flex',
    width: '500px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-none)',
    alignSelf: 'stretch',
    maxHeight: '400px',
    overflowY: 'auto',
    paddingRight: 'var(--utilities-size-0-500)',
  },
  emptyHeatMapTable: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000) var(--utilities-size-none)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    color: 'var(--content-secondary)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  utmTableHeaderCell: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  utmContainer: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-500)',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  utmTable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-none)',
    alignSelf: 'stretch',
  },
  utmTableRow: {
    display: 'flex',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-none)',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--border-rude-weak)',
  },
  footer: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    height: 'var(--utilities-size-3-500)',
    backgroundColor: 'var(--surface-default)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  footerPart1: {
    display: 'flex',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-500)',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius:
      'var(--utilities-border-radius-default) var(--utilities-border-radius-none) var(--utilities-border-radius-none) var(--utilities-border-radius-default)',
    background: 'var(--surface-default)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
  },
  footerPart2: {
    display: 'flex',
    width: '160px',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderLeft: '1px solid var(--border-default)',
    background: 'var(--surface-default)',
  },
  pagination: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  footerPart3: {
    display: 'flex',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-500)',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    justifyContent: 'flex-Start',
    borderRadius:
      'var(--utilities-border-radius-none) var(--utilities-border-radius-default) var(--utilities-border-radius-default) var(--utilities-border-radius-none)',
    borderLeft: '1px solid var(--border-default)',
    background: 'var(--surface-default)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
  },
  sectionContent: {
    width: '100%',
    height: '236px',
    display: 'flex',
    padding: '0 40px',
    justifyContent: 'space-between',
  },
  sectionCard: {
    width: '168px',
    height: '148px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    borderRadius: 'var(--utilities-border-radius-default)',
    backgroundColor: 'var(--palette-indigo-600)',
    boxShadow: 'var(--shadows-popover)',
  },
  sectionIconBox: {
    display: 'flex',
    height: '56px',
    padding: 'var(--utilities-size-0-750) 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
  },
  sectionIcon: {
    width: '32px',
    height: '32px',
    flexShrink: '0',
  },
  secondSectionIconBox: {
    width: '16px',
    height: '16px',
    opacity: '0.6',
  },
  sectionBox: {
    display: 'flex',
    width: '168px',
    height: '92px',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '2px solid var(--palette-indigo-600)',
    background: 'var(--surface-accent-pale)',
  },
  sectionBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '4px',
    flex: '1 0 0',
  },
  connection: {
    position: 'absolute',
    left: '227px',
    top: '92px',
    borderTop: '1px solid var(--border-default)',
    width: '100%',
  },
  secondConnection: {
    position: 'absolute',
    left: '227px',
    top: '152px',
    borderTop: '1px solid var(--border-default)',
    width: '100%',
  },
  sectionBoxContentTitle: {
    color: 'var(--palette-indigo-600)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  sectionBoxContentBodyCount: {
    height: '28px',
    flex: '1 0 0',
    color: 'var(--palette-indigo-600)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-lg-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  sectionBoxContentBodyDuration: {
    color: 'var(--palette-indigo-600)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    opacity: '0.4',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '260px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(94, 89, 243, 0.6)');
gradient.addColorStop(1, 'rgba(94, 89, 243, 0)');

const viewDurationGradient = ctx.createLinearGradient(0, 0, 0, 250);
viewDurationGradient.addColorStop(0, 'rgba(94, 89, 243, 0.5)');
viewDurationGradient.addColorStop(1, 'rgba(94, 89, 243, 0.25)');

const viewDurationGradientHover = ctx.createLinearGradient(0, 0, 0, 250);
viewDurationGradientHover.addColorStop(0, 'rgba(94, 89, 243, 1)');
viewDurationGradientHover.addColorStop(1, 'rgba(94, 89, 243, 0.49)');

const Multilocation3DOverviewView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { listId, companyName } = useParams();
  listId = decodeURIComponent(listId);
  companyName = decodeURIComponent(companyName);
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const visitsDateValue = useSelector(state => state.datePicker.range);
  const currentStep = useSelector(state => state.timeStep.value).find(
    ts => ts.isSelected,
  ).name;
  const [width, setWidth] = useState(0);
  const parentRef = useRef(null);

  const refVisits = useRef();
  const refVisitsScroll = useRef();

  const [filteredVisitStats, setFilteredVisitStats] = useState([]);

  const [heatMapStats, setHeatMapStats] = useState(null);
  const [downloadIconColor, setDownloadIconColor] = useState(null);
  const [hoveredVTButton, setHoveredVTButton] = useState(null);

  const stats = useAsync(async () => {
    try {
      return await getPropertyOverallStats(listId, companyName);
    } catch (error) {
      console.error('property:stats', error);
    }
  });

  const propertyHeatMapStats = useAsync(async () => {
    try {
      if (!stats.loading) {
        return await getPropertyHeatmapStats(listId, companyName);
      }
    } catch (e) {
      console.error('property:heatMapStats', e);
      return [];
    }
  }, [stats.loading]);

  const handleSavePdf = useCallback(canvas => {
    const imgData = canvas.toDataURL('image/png', 1);
    const docDefinition = {
      content: [
        {
          text: 'Tours Button Clicks Summary',
          style: 'header',
        },
        {
          text: "Information about the user's actions with the viewed tour",
          fontSize: 10,
          margin: [0, 0, 0, 10],
        },
        calculateImgSize(imgData, canvas.width, canvas.height),
      ],
      styles: {
        header: {
          fontSize: 23,
          bold: true,
        },
      },
      pageOrientation: 'landscape',
    };

    pdfMake.createPdf(docDefinition).download();
  }, []);

  const propertyVisitsStats = useAsync(async () => {
    try {
      if (!stats.loading) {
        return await getPropertyViewsChartStats(listId, companyName);
      }
    } catch (e) {
      console.error('property:visitStats', e);
      return [];
    }
  }, [stats.loading]);

  const changeVisitsTimestampHandler = useCallback(() => {
    refVisitsScroll.current.scrollLeft = 0;
  }, []);

  useEffect(() => {
    if (parentRef.current) {
      const handleResize = () => {
        setWidth(parentRef.current.offsetWidth - 422);
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (
      !propertyVisitsStats.loading &&
      propertyVisitsStats.value &&
      propertyVisitsStats.value[0]
    ) {
      dispatch(
        trySetRange(
          defaultTimeRange(moment(propertyVisitsStats.value[0].eventDate)),
        ),
      );
    }
  }, [dispatch, propertyVisitsStats]);

  useEffect(() => {
    if (
      !propertyVisitsStats.loading &&
      propertyVisitsStats.value &&
      visitsDateValue
    ) {
      setFilteredVisitStats(
        filterStatsByDate(propertyVisitsStats.value, visitsDateValue).slice(),
      );
    }
  }, [propertyVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (propertyVisitsStats.value != null && visitsDateValue) {
      const dataVisits = calculateEventsInIntervals({
        data: propertyVisitsStats.value
          .slice()
          .map(({ eventDate, eventsCount }) => {
            return {
              eventDate,
              value: eventsCount,
            };
          }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredVisitStats(
        dataVisits.reverse().map(({ eventDate, value }) => ({
          eventDate,
          eventsCount: value,
        })),
      );
    }
  }, [currentStep, propertyVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (
      !stats.loading &&
      !propertyVisitsStats.loading &&
      propertyHeatMapStats.value
    ) {
      setHeatMapStats(propertyHeatMapStats.value);
    }
  }, [stats.loading, propertyVisitsStats.loading, propertyHeatMapStats.value]);

  let locationVisits = {};
  let heatMapData = [];

  if (!stats.loading && !propertyVisitsStats.loading && heatMapStats) {
    heatMapData = heatMapStats;
  }

  if (!stats.loading && !propertyVisitsStats.loading && heatMapStats) {
    locationVisits = heatMapStats.reduce((acc, item) => {
      const subdivisionCode = item.principalSubdivisionCode.split('-')[1];
      const cityKey = `${item.city}, ${subdivisionCode}`;
      if (!acc[cityKey]) {
        acc[cityKey] = 0;
      }
      acc[cityKey] += item.visitsCount;
      return acc;
    }, {});
  }

  const sortedLocations = Object.entries(locationVisits).sort(
    (a, b) => b[1] - a[1],
  );

  const [page, setPage] = useState(0);
  const [hoveredArrowLeft, setHoveredArrowLeft] = useState(null);
  const [hoveredArrowRight, setHoveredArrowRight] = useState(null);
  const itemsPerPage = 10;

  const paginatedUnits = useMemo(() => {
    const startIndex = page * itemsPerPage;
    return data.data.slice(startIndex, startIndex + itemsPerPage);
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <Box className={classes.headerContainer}>
          <Box className={classes.headerTextSection}>
            <Box className={classes.backButton} onClick={() => navigate(-1)}>
              <ArrowLeft className={classes.backButtonIcon} />
            </Box>
            <Box className={classes.headerTitleContainer}>
              <Box className={classes.headerTitle}>
                <Box className={classes.headerTitleText}>
                  <Link to={`/overview/multi/${companyName}/${listId}`}>
                    {listId}
                  </Link>
                </Box>
                <LeadIcon2 className={classes.leadIcon2} />
                <Box
                  className={classes.headerTitleText}
                  style={{ color: 'var(--content-primary)' }}
                >
                  3D Explorer Summary
                </Box>
              </Box>
              <Box className={classes.headerSubTitleText}>
                3D Explorer Summary
              </Box>
            </Box>
          </Box>
          <Box className={classes.headerButtonsSection}>
            <a
              href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${
                !stats.loading && stats.value.locations[0].location_id
              }`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              <Box
                className={classes.virtualTourButton}
                onMouseEnter={() => setHoveredVTButton(true)}
                onMouseLeave={() => setHoveredVTButton(null)}
              >
                <Panorama
                  className={classes.buttonIcon}
                  color={
                    hoveredVTButton
                      ? 'var(--content-hover-accent-hover)'
                      : 'var(--content-accent)'
                  }
                />
                <Box
                  className={classes.virtualTourButtonText}
                  style={{
                    color: hoveredVTButton
                      ? 'var(--content-hover-accent-hover)'
                      : 'var(--content-accent)',
                  }}
                >
                  Virtual Tour
                </Box>
              </Box>
            </a>
            <Box className={classes.explorerButton}>
              <Cube
                className={classes.buttonIcon}
                color={'var(--content-inverted)'}
              />
              <Box className={classes.explorerButtonText}>3D Explorer</Box>
            </Box>
          </Box>
        </Box>
      </Header>

      <Box className={classes.infoCardContainer}>
        <Box className={classes.infoCardsRow}>
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Total Launches"
            // loading={stats.loading}
            value={139}
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Last Month Launches"
            // loading={stats.loading}
            value={46}
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Total View Duration"
            // loading={stats.loading}
            value={1256}
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Avg. View Duration"
            tooltipText="Average Views Duration"
            // loading={stats.loading}
            value={453}
          />
        </Box>
      </Box>
      <Box className={classes.infoCardContainer} style={{ paddingTop: '8px' }}>
        <Box className={classes.infoCardsRow}>
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Submit Conversion"
            // loading={stats.loading}
            value={67}
            tooltipText="Amounts of clicks to Submit button at the bottom right corner"
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Bounce rate"
            // loading={stats.loading}
            value={`43%`}
            tooltipText="Amounts of ...."
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            // loading={stats.loading}
            icon="on"
            title="Mobile"
            complexTooltip
            tooltipText={`Total views: 18%`}
            value={18}
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            // loading={stats.loading}
            icon="on"
            title="Desktop"
            complexTooltip
            tooltipText={`Total views: 82%`}
            value={82}
          />
        </Box>
      </Box>

      <Box
        className="w-full flex gap-2 mt-2 mb-2"
        style={{ borderRadius: '8px', height: '350px' }}
      >
        <Box
          className={`w-full h-auto bg-white relative ${classes.utmContainer}`}
        >
          <Box className={classes.sectionHeader}>Users Engagement</Box>
          <Box ref={parentRef} className={classes.sectionContent}>
            <Box className={classes.sectionCard}>
              <Box className={classes.sectionIconBox}>
                <Cube
                  className={classes.sectionIcon}
                  color={'var(--surface-default)'}
                />
                <ArrowRight
                  className={classes.secondSectionIconBox}
                  color={'var(--surface-default)'}
                />
              </Box>
              <Box className={classes.sectionBox}>
                <Box className={classes.sectionBoxContent}>
                  <Box className={classes.sectionBoxContentTitle}>
                    3D Overview
                  </Box>
                  <Box className={classes.sectionBoxContentBodyCount}>486</Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.connection}
              style={{ width: `${width}px` }}
            />
            <Box
              className={classes.secondConnection}
              style={{ width: `${width}px` }}
            />
            <Box
              className={classes.sectionCard}
              style={{
                backgroundColor: 'var(--palette-gray-600)',
                marginTop: '60px',
                zIndex: '1',
              }}
            >
              <Box className={classes.sectionIconBox}>
                <Squares
                  className={classes.sectionIcon}
                  color={'var(--surface-default)'}
                />
                <ArrowRight
                  className={classes.secondSectionIconBox}
                  color={'var(--surface-default)'}
                />
              </Box>
              <Box
                className={classes.sectionBox}
                style={{
                  backgroundColor: 'var(--palette-gray-100)',
                  borderColor: 'var(--palette-gray-600)',
                }}
              >
                <Box className={classes.sectionBoxContent}>
                  <Box
                    className={classes.sectionBoxContentTitle}
                    style={{ color: 'var(--palette-gray-600)' }}
                  >
                    Apartments on plan
                  </Box>
                  <Box
                    className={classes.sectionBoxContentBodyCount}
                    style={{ color: 'var(--palette-gray-600)' }}
                  >
                    344
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.sectionCard}
              style={{
                backgroundColor: 'var(--palette-gray-600)',
                marginTop: '60px',
                zIndex: '1',
              }}
            >
              <Box className={classes.sectionIconBox}>
                <Lotus
                  className={classes.sectionIcon}
                  color={'var(--surface-default)'}
                />
                <ArrowRight
                  className={classes.secondSectionIconBox}
                  color={'var(--surface-default)'}
                />
              </Box>
              <Box
                className={classes.sectionBox}
                style={{
                  backgroundColor: 'var(--palette-gray-100)',
                  borderColor: 'var(--palette-gray-600)',
                }}
              >
                <Box className={classes.sectionBoxContent}>
                  <Box
                    className={classes.sectionBoxContentTitle}
                    style={{ color: 'var(--palette-gray-600)' }}
                  >
                    Amenities
                  </Box>
                  <Box
                    className={classes.sectionBoxContentBodyCount}
                    style={{ color: 'var(--palette-gray-600)' }}
                  >
                    106
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.sectionCard}
              style={{
                backgroundColor: 'var(--palette-gray-600)',
                marginTop: '60px',
                zIndex: '1',
              }}
            >
              <Box className={classes.sectionIconBox}>
                <Map
                  className={classes.sectionIcon}
                  color={'var(--surface-default)'}
                />
                <ArrowRight
                  className={classes.secondSectionIconBox}
                  color={'var(--surface-default)'}
                />
              </Box>
              <Box
                className={classes.sectionBox}
                style={{
                  backgroundColor: 'var(--palette-gray-100)',
                  borderColor: 'var(--palette-gray-600)',
                }}
              >
                <Box className={classes.sectionBoxContent}>
                  <Box
                    className={classes.sectionBoxContentTitle}
                    style={{ color: 'var(--palette-gray-600)' }}
                  >
                    Neighborhood
                  </Box>
                  <Box
                    className={classes.sectionBoxContentBodyCount}
                    style={{ color: 'var(--palette-gray-600)' }}
                  >
                    51
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.sectionCard}
              style={{ backgroundColor: 'var(--palette-green-600)' }}
            >
              <Box className={classes.sectionIconBox}>
                <Build
                  className={classes.sectionIcon}
                  color={'var(--surface-default)'}
                />
                <Check
                  className={classes.secondSectionIconBox}
                  color={'var(--surface-default)'}
                />
              </Box>
              <Box
                className={classes.sectionBox}
                style={{
                  backgroundColor: 'var(--palette-green-100)',
                  borderColor: 'var(--palette-green-600)',
                }}
              >
                <Box className={classes.sectionBoxContent}>
                  <Box
                    className={classes.sectionBoxContentTitle}
                    style={{ color: 'var(--palette-green-600)' }}
                  >
                    Apartment Card
                  </Box>
                  <Box
                    className={classes.sectionBoxContentBodyCount}
                    style={{ color: 'var(--palette-green-600)' }}
                  >
                    1298
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        className="w-full flex gap-2 mt-2 mb-2"
        style={{ borderRadius: '8px', minHeight: '273px' }}
      >
        <Box
          className="w-3/5 h-auto bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '8px',
            boxShadow: 'var(--shadows-subtle)',
          }}
        >
          <Box className={classes.chartHeaderContainer}>
            <Box className="flex">
              <Tooltip
                placement="top"
                title="Amount of launches applied range of times"
                classes={tooltipClass}
              >
                <Box className={classes.sectionHeader}>Launches Summary</Box>
              </Tooltip>
              <Box
                className={classes.downloadIconContainer}
                onMouseEnter={() => setDownloadIconColor('5E59F3')}
                onMouseLeave={() => setDownloadIconColor(null)}
                onClick={() =>
                  handleSavePdf(refVisits.current.chartInstance.canvas)
                }
              >
                <Download
                  className={classes.downloadIcon}
                  color={downloadIconColor || '2C313A'}
                />
              </Box>
              <Box className="flex">
                <TimeStep
                  visitsDateValue={visitsDateValue}
                  onClick={changeVisitsTimestampHandler}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
              }}
            />
            <MultiDateRangePicker />
          </Box>
          <Box
            className={`w-full relative flex flex-col ${classes.container}`}
            ref={refVisitsScroll}
            style={{
              overflow: 'auto',
            }}
          >
            <Box
              style={{
                position: 'relative',
                height: '100%',
                minWidth:
                  filteredVisitStats.length * calculateChartSize(currentStep),
                overflowY: 'overlay',
              }}
            >
              {filteredVisitStats.length !== 0 ? (
                <Line
                  ref={refVisits}
                  data={{
                    datasets: [
                      {
                        backgroundColor: gradient,
                        lineTension: 0.01,
                        borderColor: '#5E59F3',
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent',
                        pointRadius: size(15),
                        pointHoverRadius: size(15),
                        borderWidth: 1,
                        parsing: false,
                        data: filteredVisitStats.map(item => item.eventsCount),
                        label: '',
                      },
                    ],
                    labels: formatLabels(filteredVisitStats, currentStep),
                  }}
                  options={{
                    normalized: true,
                    animation: false,
                    cornerRadius: size(20),
                    layout: {
                      padding: {
                        right: size(13),
                      },
                    },
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: true,
                    devicePixelRatio: window.devicePixelRatio * 2,

                    hover: {
                      onHover(e, [chart]) {
                        if (!chart) {
                          dispatch(
                            updateVisitsTooltipInfo({
                              show: false,
                              showDashes: false,
                              showLabel: false,
                              showCircle: false,
                            }),
                          );
                          return;
                        }

                        const value = filteredVisitStats[chart._index];

                        dispatch(
                          setVisitsTooltipInfo({
                            showCircle: true,
                            showDashes: true,
                            type: TOOLTIP_TYPE.SMALL,
                            show: true,
                            value,
                            label: chart._xScale.ticks[chart._index],
                            smallTooltipText: `${value.eventsCount}`,
                            dashesHeight: `${
                              refVisits.current.chartInstance.height - 20
                            }px`,
                            showLabel: true,
                            tooltipLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            tooltipTop: chart._view.y + 28,
                            circleLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              10,
                            circleTop: chart._view.y + 50,
                            dashesLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            dashesTop:
                              e.target.parentElement.parentElement.offsetTop,
                            labelLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            labelBottom: 15,
                          }),
                        );
                      },
                    },
                    tooltips: {
                      enabled: false,
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: size(8),
                            reverse: true,
                          },
                          categoryPercentage: 0.97,
                          barPercentage: 0.97,
                          barThickness: 28,
                          scaleLabel: {
                            display: true,
                            labelString: 'Date',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontStyle: 'bold',
                            fontColor: '#000',
                            stepSize: 250,
                            beginAtZero: true,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: 'Views',
                          },
                        },
                      ],
                    },
                  }}
                />
              ) : (
                <Skeleton height="99%" />
              )}
            </Box>
          </Box>
          <GraphTooltip store="visitsTooltip" />
        </Box>
        <Box
          className={`w-2/5 h-auto bg-white relative ${classes.utmContainer}`}
        >
          <Box className={classes.sectionHeader}>Amenities Views</Box>
          <Box className={classes.utmTable}>
            <Box className={classes.utmTableRow}>
              <Box
                style={{ flex: '1 0 0' }}
                className={classes.utmTableHeaderCell}
              >
                Amenities
              </Box>
              <Box
                style={{
                  width: '80px',
                  textAlign: 'right',
                }}
                className={classes.utmTableHeaderCell}
              >
                Total views
              </Box>
            </Box>
            <Box
              style={{ borderBottom: '1px solid var(--border-default)' }}
              className={classes.utmTableRow}
            >
              <Box
                style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                className={classes.utmTableHeaderCell}
              >
                Courtyard
              </Box>
              <Box
                style={{
                  width: '80px',
                  textAlign: 'right',
                  color: 'var(--content-primary)',
                }}
                className={classes.utmTableHeaderCell}
              >
                113
              </Box>
            </Box>
            <Box
              style={{ borderBottom: '1px solid var(--border-default)' }}
              className={classes.utmTableRow}
            >
              <Box
                style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                className={classes.utmTableHeaderCell}
              >
                Fitness
              </Box>
              <Box
                style={{
                  width: '80px',
                  textAlign: 'right',
                  color: 'var(--content-primary)',
                }}
                className={classes.utmTableHeaderCell}
              >
                104
              </Box>
            </Box>
            <Box
              style={{ borderBottom: '1px solid var(--border-default)' }}
              className={classes.utmTableRow}
            >
              <Box
                style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                className={classes.utmTableHeaderCell}
              >
                Rooftop
              </Box>
              <Box
                style={{
                  width: '80px',
                  textAlign: 'right',
                  color: 'var(--content-primary)',
                }}
                className={classes.utmTableHeaderCell}
              >
                98
              </Box>
            </Box>
            <Box
              style={{ borderBottom: '1px solid var(--border-default)' }}
              className={classes.utmTableRow}
            >
              <Box
                style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                className={classes.utmTableHeaderCell}
              >
                Clubroom 1st floor
              </Box>
              <Box
                style={{
                  width: '80px',
                  textAlign: 'right',
                  color: 'var(--content-primary)',
                }}
                className={classes.utmTableHeaderCell}
              >
                65
              </Box>
            </Box>
            <Box
              style={{ borderBottom: '1px solid var(--border-default)' }}
              className={classes.utmTableRow}
            >
              <Box
                style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                className={classes.utmTableHeaderCell}
              >
                Clubroom 2nd floor
              </Box>
              <Box
                style={{
                  width: '80px',
                  textAlign: 'right',
                  color: 'var(--content-primary)',
                }}
                className={classes.utmTableHeaderCell}
              >
                65
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        className="w-full flex gap-2 mt-2 mb-2"
        style={{ borderRadius: '8px', minHeight: '273px' }}
      >
        <Box
          className={`w-full h-auto bg-white relative ${classes.heatMapContainer}`}
        >
          <Box className={classes.sectionHeader}>User Locations</Box>
          <Box className={classes.heatMapContent}>
            <Box className={classes.heatMapTable}>
              <Box className={classes.utmTableRow}>
                <Box
                  style={{ flex: '1 0 0' }}
                  className={classes.utmTableHeaderCell}
                >
                  Region
                </Box>
                <Box
                  style={{
                    width: 'var(--utilities-size-5-000)',
                    textAlign: 'right',
                  }}
                  className={classes.utmTableHeaderCell}
                >
                  Total visits
                </Box>
              </Box>
              {sortedLocations.length > 0 ? (
                sortedLocations.map(([city, count]) => (
                  <Box
                    key={city}
                    style={{ borderBottom: '1px solid var(--border-default)' }}
                    className={classes.utmTableRow}
                  >
                    <Box
                      style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                      className={classes.utmTableHeaderCell}
                    >
                      {city}
                    </Box>
                    <Box
                      style={{
                        width: 'var(--utilities-size-5-000)',
                        textAlign: 'right',
                        color: 'var(--content-primary)',
                      }}
                      className={classes.utmTableHeaderCell}
                    >
                      {count}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box className={classes.emptyHeatMapTable}>Nothing found</Box>
              )}
            </Box>
            {heatMapData && <HeatMap data={heatMapData} />}
          </Box>
        </Box>
      </Box>

      <Box
        className="w-full flex gap-2 mt-2 mb-2"
        style={{ borderRadius: '8px', minHeight: '273px' }}
      >
        <Box
          className={`w-full h-auto bg-white relative ${classes.utmContainer}`}
        >
          <Box className={classes.sectionHeader}>Units Summary</Box>
          <Box className={classes.utmTable}>
            <Box className={classes.utmTableRow}>
              <Box
                style={{ flex: '1 0 0' }}
                className={classes.utmTableHeaderCell}
              >
                Unit Title
              </Box>
              <Box
                style={{
                  width: '80px',
                  textAlign: 'right',
                }}
                className={classes.utmTableHeaderCell}
              >
                Total views
              </Box>
              <Box
                style={{
                  width: '140px',
                  textAlign: 'right',
                }}
                className={classes.utmTableHeaderCell}
              >
                Conversion
              </Box>
            </Box>
            {data.data.length > 0 &&
              paginatedUnits.map(item => (
                <Box
                  style={{ borderBottom: '1px solid var(--border-default)' }}
                  className={classes.utmTableRow}
                  key={item.id}
                >
                  <Box
                    style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                    className={classes.utmTableHeaderCell}
                  >
                    {item.unitTitle}
                  </Box>
                  <Box
                    style={{
                      width: '80px',
                      textAlign: 'right',
                      color: 'var(--content-primary)',
                    }}
                    className={classes.utmTableHeaderCell}
                  >
                    {item.visitsCount}
                  </Box>
                  <Box
                    style={{
                      width: '140px',
                      textAlign: 'right',
                      color: 'var(--content-primary)',
                    }}
                    className={classes.utmTableHeaderCell}
                  >
                    {item.conversion}
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>

      <Box
        className="w-full flex gap-2 mt-2 mb-2"
        style={{ borderRadius: '8px' }}
      >
        {data.data.length > itemsPerPage ? (
          <Box className={classes.footer}>
            <IconButton
              className={classes.footerPart1}
              onMouseEnter={() => setHoveredArrowLeft(true)}
              onMouseLeave={() => setHoveredArrowLeft(null)}
              onClick={() => handleChangePage(null, page - 1)}
              disabled={page === 0}
            >
              <ArrowLeft
                color={
                  page === 0
                    ? '#2C313A33'
                    : hoveredArrowLeft
                    ? '#5E59F3'
                    : '#2C313A'
                }
              />
            </IconButton>
            <Box className={classes.footerPart2}>
              <Box className={classes.pagination}>
                Page {page + 1} of {Math.ceil(data.data.length / itemsPerPage)}
              </Box>
            </Box>
            <IconButton
              className={classes.footerPart3}
              onMouseEnter={() => setHoveredArrowRight(true)}
              onMouseLeave={() => setHoveredArrowRight(null)}
              onClick={() => handleChangePage(null, page + 1)}
              disabled={page >= Math.ceil(data.data.length / itemsPerPage) - 1}
            >
              <ArrowRight
                color={
                  page >= Math.ceil(data.data.length / itemsPerPage) - 1
                    ? '#2C313A33'
                    : hoveredArrowRight
                    ? '#5E59F3'
                    : '#2C313A'
                }
              />
            </IconButton>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Page>
  );
};

export default Multilocation3DOverviewView;
