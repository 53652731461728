import React from 'react';

export const PlayCircle = ({ className, color = '5E59F3' }) => {
  return (
    <svg
      className={`play-circle-icon ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="play-circle">
        <path
          d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM16.5225 11.3644L10.5225 7.61438C10.409 7.54344 10.2786 7.50416 10.1448 7.50063C10.011 7.4971 9.87868 7.52945 9.76159 7.5943C9.64451 7.65916 9.54691 7.75416 9.47893 7.86946C9.41096 7.98476 9.37507 8.11615 9.375 8.25V15.75C9.37507 15.8838 9.41096 16.0152 9.47893 16.1305C9.54691 16.2458 9.64451 16.3408 9.76159 16.4057C9.87868 16.4706 10.011 16.5029 10.1448 16.4994C10.2786 16.4958 10.409 16.4566 10.5225 16.3856L16.5225 12.6356C16.6302 12.5682 16.719 12.4745 16.7806 12.3633C16.8421 12.2521 16.8744 12.1271 16.8744 12C16.8744 11.8729 16.8421 11.7479 16.7806 11.6367C16.719 11.5255 16.6302 11.4318 16.5225 11.3644ZM10.875 14.3972V9.60281L14.7103 12L10.875 14.3972Z"
          fill={`#${color}`}
        />
      </g>
    </svg>
  );
};
