import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Page from '~/components/shared/Page';

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-000)',
  },
  headerContainer: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    padding: 'var(--utilities-size-1-500) var(--utilities-size-none)',
    position: 'relative',
    width: '100%',
  },
  header: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xl)',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-xl-line)',
    marginTop: '-1px',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  header2: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  cardsContainer: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    display: 'flex',
    flex: '0 0 auto',
    gap: 'var(--utilities-size-0-500)',
    position: 'relative',
    width: '100%',
  },
  card: {
    alignItems: 'flex-start',
    backgroundColor: 'var(--surface-default)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1,
    height: '240px',
    justifyContent: 'space-between',
    padding: 'var(--utilities-size-1-000)',
    position: 'relative',
  },
  cardHeaderContainer: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    gap: 'var(--utilities-size-0-500)',
    position: 'relative',
    width: '100%',
  },
  cardHeader: {
    alignSelf: 'stretch',
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
  },
  cardHeader2: {
    alignSelf: 'stretch',
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-lg-line)',
    position: 'relative',
  },
  workingTime: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    position: 'relative',
    width: 'fit-content',
  },
  card2: {
    alignItems: 'flex-start',
    backgroundColor: 'var(--surface-default)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1,
    gap: 'var(--utilities-size-0-500)',
    height: '240px',
    padding: 'var(--utilities-size-1-000)',
    position: 'relative',
  },
}));

const ContactsView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.mainContainer} title="Contacts">
      <Box className={classes.headerContainer}>
        <Box className={classes.header}>Contact Us</Box>
        <p className={classes.header2}>Please reach out with any questions</p>
      </Box>
      <Box className={classes.cardsContainer}>
        <Box className={classes.card}>
          <Box className={classes.cardHeaderContainer}>
            <Box className={classes.cardHeader}>Headquarters</Box>
            <Typography className={classes.cardHeader2}>
              111 Congress Ave, Suite 500
              <br />
              Austin, TX 78701
            </Typography>
          </Box>
          <Typography className={classes.workingTime}>
            Working days: Monday — Friday
            <br />
            Working hours: 9 AM — 6 PM
          </Typography>
        </Box>
        <Box className={classes.card2}>
          <Box className={classes.cardHeader}>Phone</Box>
          <Box className={classes.cardHeader2}>512-931-1766</Box>
        </Box>
        <Box className={classes.card2}>
          <Box className={classes.cardHeader}>E-mail</Box>
          <Box className={classes.cardHeader2}>info@theviewpro.com</Box>
        </Box>
      </Box>
    </Page>
  );
};

export default ContactsView;
