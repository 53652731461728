import React from 'react';

export const LeadIcon2 = ({ className, color = '2C313A' }) => {
  return (
    <svg
      className={`lead-icon-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.031 12.5306L9.53104 20.0306C9.46136 20.1002 9.37863 20.1555 9.28759 20.1932C9.19654 20.2309 9.09896 20.2503 9.00042 20.2503C8.90187 20.2503 8.80429 20.2309 8.71324 20.1932C8.6222 20.1555 8.53947 20.1002 8.46979 20.0306C8.40011 19.9609 8.34483 19.8781 8.30712 19.7871C8.26941 19.6961 8.25 19.5985 8.25 19.4999C8.25 19.4014 8.26941 19.3038 8.30712 19.2128C8.34483 19.1217 8.40011 19.039 8.46979 18.9693L15.4401 11.9999L8.46979 5.03055C8.32906 4.88982 8.25 4.69895 8.25 4.49993C8.25 4.30091 8.32906 4.11003 8.46979 3.9693C8.61052 3.82857 8.80139 3.74951 9.00042 3.74951C9.19944 3.74951 9.39031 3.82857 9.53104 3.9693L17.031 11.4693C17.1008 11.539 17.1561 11.6217 17.1938 11.7127C17.2316 11.8038 17.251 11.9014 17.251 11.9999C17.251 12.0985 17.2316 12.1961 17.1938 12.2871C17.1561 12.3782 17.1008 12.4609 17.031 12.5306Z"
        fill={`#${color}`}
      />
    </svg>
  );
};
