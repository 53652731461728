/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';

import Page from '~/components/shared/Page';
import { getHostingLastRunTime } from '~/services/quickBooksApi';
import { authRoles } from '~/constants';
import HostingTable from './components/HostingTable';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'var(--surface-pale)',
    minHeight: '100%',
    padding: 'var(--utilities-size-1-500)',
    '& .MuiPaper-rounded': {
      borderRadius: 'var(--utilities-border-radius-default)',
      background: 'var(--surface-default)',
      boxShadow: 'var(--shadows-subtle)',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: 'var(--utilities-size-1-500)',
  },
  header: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xl)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-xl-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  dataUpdate: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const BillingsView = () => {
  const classes = useStyles();
  const [lastRunTime, setLastRunTime] = useState(null);
  const user = useSelector(state => state.auth.user);
  const isAdmin =
    user.role === authRoles.ADMIN || user.role === authRoles.SUPER_ADMIN;

  useEffect(() => {
    if (isAdmin) {
      getHostingLastRunTime().then(res => setLastRunTime(res));
    }
  }, [isAdmin]);

  return (
    <Page className={classes.root} title="Billings">
      <Box className={classes.headerContainer}>
        <Box className={classes.header}>Subscriptions Billing Info</Box>
        {isAdmin && (
          <>
            {lastRunTime ? (
              <Box className={classes.dataUpdate}>
                Last data update: {new Date(lastRunTime).toLocaleString()}
              </Box>
            ) : (
              <Box>Loading...</Box>
            )}
          </>
        )}
      </Box>
      <HostingTable />
    </Page>
  );
};

export default BillingsView;
